.cookie-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;

  p {
    font-size: 0.6rem;
  }

  button {
    font-size: 0.6rem;
    color: var(--primary);
  }
}
