.cartButton {
  position: absolute !important;
  top: 0;
  right: 0;
  border-radius: 9px;
  height: 18px;
  min-width: 18px !important;
  text-align: center;
  transform: translateX(50%);
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
