@import 'styles/bootstrap-core.scss';

.bgLightGrey {
  @include media-breakpoint-down(sm) {
    ul {
      max-height: 270px;
    }
  }

  @include media-breakpoint-down(470px) {
    ul {
      max-height: none;
    }
  }
}
