@import 'styles/bootstrap-core.scss';

@keyframes slideDown {
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }
}

@keyframes slideLeftFromZero {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
}

.header-btn-link {
  color: #333;
  padding-right: 0;
  text-decoration: underline;
}

.header-menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1001;
  border-radius: 0;

  .header-menu-primary-bar {
    background: #f4f4f4;
    position: relative;
    z-index: 20;

    @include media-breakpoint-up(lg) {
      nav {
        min-height: 48px;
      }
    }
  }

  &-second-bar {
    content: '';
    display: block;
    width: 100%;
    transition: all 0.2s ease;

    ul {
      list-style-type: none;
      padding: 0;
    }

    .header-menu-second-bg-primary {
      min-height: 66px;
      position: relative;
      background: var(--primary);
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;

      ul li a {
        font-size: 0.9rem;
        text-decoration: none;
      }

      @include media-breakpoint-down(lg) {
        min-height: 99px;
      }

      @include media-breakpoint-down(sm) {
        min-height: auto;
      }
    }

    @include media-breakpoint-up(md) {
      content: '';
      display: block;

      .header-menu-second-bg-primary {
        background: var(--primary);
      }
    }
  }

  &-logo-img {
    display: block;
    //font-size: 1.6rem;
    margin-bottom: -75px;
    height: 100px;
    width: 100px;

    @include media-breakpoint-up(md) {
      //font-size: 2.5rem;
      margin-bottom: -80px;
      margin-top: 0;
    }

    @include media-breakpoint-down(md) {
      //font-size: 2.5rem;
      height: 74px;
      width: 74px;
    }
  }

  .donate-now-btn {
    transition: all 0.2s ease;
    will-change: transform, opacity;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    touch-action: none;
    height: 44px;
    line-height: 0.4rem;

    &.opened {
      animation: slideDown 0.2s ease forwards;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      touch-action: auto;
      transform: none;
    }

    @include media-breakpoint-down(sm) {
      animation: none !important;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      touch-action: auto;
      transform: none;
    }
  }

  .second-level-menu {
    position: relative;
    z-index: 0;
    animation: slideDown 0.3s ease forwards;
    will-change: opacity, transform;
    box-shadow: 0 0 0 4px white, 0 5px 15px 3px rgba(0, 0, 0, 0.2);
  }

  .second-level-menu-mobile {
    transform: translateX(-100%);
    animation: slideLeftFromZero 0.3s ease forwards;
    will-change: opacity, transform;
  }

  ul ul {
    padding-left: 1.2rem;
  }

  .menu-first-level-description-desktop {
    @include media-breakpoint-down(md) {
      background: var(--light-grey);
    }
  }

  @include media-breakpoint-down(sm) {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;

    .header-btn-link {
      padding-right: 0;
      font-size: 0.75rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .donate-now-btn {
      font-size: 0.75rem;
      padding: 0.6rem 1.3rem;
      height: 42px;
    }

    &.opened {
      .header-menu-second-bar {
        height: calc(100vh - 31px);
        background: rgba($primary, 0.95);
        overflow-x: hidden;
      }
    }
  }
}

.userMenuSmallScreen {
  display: none;
}

@media (max-width: 370px) {
  .userMenuOnTop {
    display: none !important;
  }

  .userMenuSmallScreen {
    display: flex !important;
  }
}
