.circle {
  position: relative;
  border-radius: 50%;
  box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100px;
  height: 100px;
  max-width: 100vw;
  max-height: 100vw;

  &-xs {
    width: 40px;
    height: 40px;
  }

  &-sm {
    width: 60px;
    height: 60px;
  }

  &-lg {
    width: 145px;
    height: 145px;
  }

  &-xl {
    width: 260px;
    height: 260px;
  }

  > img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
  }
}
